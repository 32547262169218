import { useReducer } from "react";
import { Link } from "react-router-dom";
import BoxWrapper from "../../components/BoxWrapper";
import AdminRoute from "../auth/RestrictedRoute";
import { DriversSvg } from "../../components/SideBarMenu";
import RenderTable from "../../components/RenderTable";
import Pagination from "../../components/Pagination";
import { pluralize } from "../../helpers/general";
import { general } from "../../locales/general";
import {
  bulkDeleteReducer,
  bulkDeleteState
} from "../../context/bulkDeleteReducer";
import DeleteIcon from "../../components/icons/DeleteIcon";
import { colors } from "../../config/theme";
import { CSpinner } from "@coreui/react";
import DriversTable from "./DriverTable";
import { useDriverQuery } from "../../hooks/drivers/useDriverQuery";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import {
  DriverProfileStatus,
  DriverProfileStatusDisplayValue
} from "../../helpers/enums";

type KeysofStatus = keyof typeof DriverProfileStatusDisplayValue;

export default function Drivers() {
  const {
    data,
    isLoading,
    currentPage,
    setCurrentPage,
    pageSize,
    searchHandler,
    removeDriver,
    mutating,
    status,
    expirationStatus,
    expirationStatusHandler
  } = useDriverQuery();

  const [DriverToBeDeleted, dispatch] = useReducer(
    bulkDeleteReducer,
    bulkDeleteState
  );

  const handleBulkDelete = () => {
    DriverToBeDeleted.ids.forEach((id: string) => {
      if (!id) return;
      removeDriver(id);
      DriverToBeDeleted.ids = [];
    });
  };

  console.log(expirationStatus);

  return (
    <AdminRoute>
      <section>
        <h5 className="fs-4 fw-bold mb-2">Chauffeurs</h5>
        <BoxWrapper>
          <div className="d-flex  justify-content-between align-items-baseline before-table">
            <div className="d-flex align-items-center gap-4 p-3 delete-add">
              {/* <Link
                to=""
                className="outline-none border-0 btn btn-primary rounded-pill text-white d-flex align-items-center gap-2 justify-content-center shadow-secondary"
              >
                + Ajouter un chauffeur
              </Link> */}

              <div className="align-items-center gap-2">
                <label htmlFor="status">Statut du document:</label>

                <select
                  name="expirationStatus"
                  id="expirationStatus"
                  value={expirationStatus?.toString()}
                  onChange={e =>
                    expirationStatusHandler(e.target.value === "true")
                  }
                >
                  <option value="false">Tout</option>
                  <option value="true">Sur le point d&apos;expirer</option>
                </select>
              </div>
              <button
                disabled={mutating || DriverToBeDeleted?.ids.length === 0}
                onClick={handleBulkDelete}
                className="outline-none  btn btn-outline-danger rounded-pill text-danger d-flex align-items-center gap-2 justify-content-center shadow-primary"
              >
                {!mutating ? (
                  <>
                    <DeleteIcon fill={colors.primary} /> Supprimer
                  </>
                ) : (
                  <>
                    <CSpinner size="sm" /> {general.fr.wait}
                  </>
                )}
              </button>
            </div>
            <div className="search-form">
              <div className=" input-field">
                <MagnifyingGlassIcon color="gray" width={20} />
                <input
                  type="text"
                  className="w-50 border-0  outline-none rounded-sm p-1 text-gray fs-6"
                  placeholder="Rechercher"
                  onChange={e => searchHandler(e.target.value)}
                />
              </div>
            </div>
            <div
              className="d-flex align-items-center justify-content-center gap-3 bg-gradient-primary p-3"
              style={{ borderTopRightRadius: "8px", alignSelf: "flex-start" }}
            >
              <DriversSvg className="fill-white" />
              <p className="fs-5 fw-bold m-0  text-white text-pre">
                {data?.meta?.totalItems}{" "}
                {pluralize("chauffeur", data?.meta?.totalItems)}
              </p>
            </div>
          </div>
          <div className="table-responsive">
            <RenderTable
              loading={isLoading}
              render={() =>
                data?.items?.length > 0 ? (
                  <DriversTable
                    drivers={data?.items as Driver[]}
                    DriversTableToBeDeleted={dispatch}
                  />
                ) : (
                  <p className="text-center fs-5 my-2">{general.fr.noData}</p>
                )
              }
            />
          </div>
        </BoxWrapper>
        <Pagination
          currentPage={currentPage}
          onPageChange={page => {
            setCurrentPage(page);
          }}
          siblingCount={1}
          totalCount={data?.meta?.totalPages}
          pageSize={pageSize}
        />
      </section>
    </AdminRoute>
  );
}
