import {
  CFormInput,
  CModal,
  CModalBody,
  CNav,
  CNavItem,
  CNavLink,
  CTabContent,
  CTabPane
} from "@coreui/react";
import React, { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useLocation, useParams } from "react-router-dom";
import { apiConfig } from "../config/apiConfig";
import { DriverProfileStatus } from "../helpers/enums";
import { useCookie } from "../hooks/useCookie";
import { useToast } from "../hooks/useToast";
import { general } from "../locales/general";
import apiService from "../service/apiService";
import noImage from "./staticsLogos/no_image.jpg";

import { add } from "date-fns";
import {
  formatDateString,
  isDateApproaching,
  toDateInputValue
} from "../helpers/general";

type Docs = {
  noImage: boolean;
  label: string;
  name: string;
  status?: string | null;
  image?: string | null;
  expires?: string | null;
  showExpirationField: boolean;
  expirationField?: string;
};

type DriverDocumentProp = {
  doc: Docs;
  onAccept: () => void;
  onRefuse: () => void;
  onView: () => void;
  loading?: boolean;
};
export default function DriverDocument(props: DriverDocumentProp) {
  const { doc, onAccept, onRefuse, onView, loading = false } = props;
  const [uploadDoc, setUploadDoc] = useState(false);

  const isExpiring = doc?.expires
    ? isDateApproaching(new Date(doc?.expires), 30)
    : false;

  return (
    <div>
      <div className="bg-image ripple shadow-1-strong rounded h-100">
        <img
          src={doc.noImage ? noImage : doc?.image ?? ""}
          alt={doc?.label}
          className={`w-100 rounded-4 border border-5  border-${
            doc?.status === DriverProfileStatus.APPROVED
              ? "success"
              : doc?.status === DriverProfileStatus.DECLINED
              ? "red"
              : "danger"
          }`}
          style={{
            height: "235px",
            objectFit: "cover",
            cursor: "pointer"
          }}
          onClick={onView}
        />
        <p className="mt-2 mb-1">{doc?.label}</p>
        {doc?.expires && (
          <p className="my-0">
            Date d&apos;expiration : {formatDateString(doc?.expires)}
          </p>
        )}
        {isExpiring && (
          <p className="text-red my-2 fw-semibold">
            Sur le point d&apos;expirer
          </p>
        )}
        <div className="d-flex flex-wrap align-items-center justify-content-center gap-2 mt-2">
          {doc?.status !== DriverProfileStatus.APPROVED && !doc.noImage && (
            <button
              className="btn btn-success shadow-secondary rounded-pill px-2 py-1 text-sm text-white"
              onClick={onAccept}
              disabled={loading}
            >
              Accepter
            </button>
          )}
          {doc?.status !== DriverProfileStatus.DECLINED && !doc.noImage && (
            <button
              className="btn btn-danger shadow-primary rounded-pill px-2 py-1 text-sm text-white"
              onClick={onRefuse}
              disabled={loading}
            >
              Refuser
            </button>
          )}

          <button
            type="button"
            className="btn btn-success px-2 py-1 text-sm bg-light rounded-pill"
            onClick={() => setUploadDoc(true)}
          >
            Modifier
          </button>
        </div>
      </div>
      {uploadDoc && (
        <UploadDocumentModal
          isOpen={uploadDoc}
          onClose={() => setUploadDoc(false)}
          doc={doc}
          defaultIndex={doc?.expirationField && !doc.noImage ? 0 : 1}
        />
      )}
    </div>
  );
}

type UploaderProps = {
  isOpen: boolean;
  onClose: VoidFunction;
  doc: Docs;
  defaultIndex?: number;
};

function UploadDocumentModal(props: UploaderProps) {
  const { isOpen, onClose, doc, defaultIndex = 0 } = props;
  const showExpiration = doc?.expirationField && !doc.noImage;

  const [activeTab, setActiveTab] = useState(defaultIndex);
  const { hash } = useLocation();

  const [date, onDateChange] = useState<Date | null>(null);

  useEffect(() => {
    console.log({ defaultIndex });
    setActiveTab(defaultIndex);

    // return () => setActiveTab(0);
  }, [defaultIndex]);

  // useEffect(() => {
  //   if (hash) {
  //     const index = ["#balance", "#docs"].indexOf(hash);
  //     index >= 0 && setActiveTab(index);
  //   }
  // }, [hash]);
  const params = useParams();
  const { token } = useCookie("vToken");
  const queryClient = useQueryClient();
  const driverId = params?.driverId!;

  const updateExpirationDate = useMutation({
    mutationFn: (data: any) =>
      apiService.MakePutRequest(
        apiConfig.users.drivers.set_expiration_date(driverId),
        data,
        token
      ),
    mutationKey: [token, driverId, "set-expiration-date"]
  });

  const { toastError, toastSuccess } = useToast();

  const onExpirationSave = async () => {
    try {
      if (!date) {
        toastError("Veuillez indiquer une date d'expiration");
        return;
      }
      updateExpirationDate.mutate(
        {
          type: doc.expirationField,
          expiration: date
        },
        {
          onSuccess: () => {
            toastSuccess("date d'expiration mise à jour");
            queryClient.invalidateQueries();
            onClose();
          },
          onError() {
            toastError(general.fr.message.operationFailed);
          }
        }
      );
    } catch (error) {
      toastError(general.fr.message.operationFailed);
    }
  };

  return (
    <CModal visible={isOpen} onClose={onClose} alignment="center">
      <CModalBody>
        <CNav variant="tabs" className="mb-2">
          {showExpiration && (
            <CNavItem
              href="#balance"
              active={activeTab === 0}
              onClick={() => setActiveTab(0)}
            >
              <CNavLink
                className={`${activeTab === 0 ? "text-danger" : "text-black"}`}
                active={activeTab === 0}
              >
                Changer la date d&apos;expiration
              </CNavLink>
            </CNavItem>
          )}
          <CNavItem
            href="#docs"
            active={activeTab === 1}
            onClick={() => setActiveTab(1)}
          >
            <CNavLink
              active={activeTab === 1 || !showExpiration}
              className={`${activeTab === 1 ? "text-danger" : "text-black"}`}
            >
              Document
            </CNavLink>
          </CNavItem>
        </CNav>
        <CTabContent>
          {showExpiration && (
            <CTabPane visible={activeTab === 0}>
              <div className="mt-3">
                <label htmlFor="date" className="d-block mb-1">
                  Date d&apos;expiration
                </label>
                <CFormInput
                  type="date"
                  onChange={e => onDateChange(new Date(e.target.value))}
                  defaultValue={
                    doc?.expires
                      ? (toDateInputValue(doc.expires) as string)
                      : undefined
                  }
                />
                <div className="mt-4">
                  <button
                    className="btn btn-success shadow-secondary rounded-sm p-2 mb-1 text-sm text-white"
                    type="button"
                    onClick={onExpirationSave}
                    disabled={updateExpirationDate.isLoading}
                  >
                    {updateExpirationDate.isLoading
                      ? "Enregistrement..."
                      : "Enregistrer"}
                  </button>
                </div>
              </div>
            </CTabPane>
          )}
          <CTabPane visible={activeTab === 1}>
            <UpdateDocument {...props} />
          </CTabPane>
        </CTabContent>
      </CModalBody>
    </CModal>
  );
}

function UpdateDocument(props: UploaderProps) {
  const { onClose, doc } = props;
  const minDate = add(new Date(), { days: 1 });

  const [files, setFiles] = useState<FileList | null>();
  const [date, onDateChange] = useState<Date | null>(null);

  const params = useParams();
  const { token } = useCookie("vToken");
  const queryClient = useQueryClient();
  const driverId = params?.driverId!;

  const uploadFileMutation = useMutation({
    mutationFn: (data: any) =>
      apiService.MakePutRequest(
        apiConfig.users.drivers.upload_docs(driverId),
        data,
        token,
        true
      ),
    mutationKey: [token, driverId, "upload-docs"]
  });

  const { toastError, toastSuccess } = useToast();

  const onFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFiles(e.target.files);
  };

  const onDocumentSave = async () => {
    try {
      uploadFileMutation.mutate(
        {
          type: doc.name,
          document: files?.[0],
          expiration: date
        },
        {
          onSuccess: () => {
            toastSuccess("success");
            queryClient.invalidateQueries();
            setFiles(null);
            onClose();
          },
          onError() {
            toastError(general.fr.message.operationFailed);
          }
        }
      );
    } catch (error) {
      toastError(general.fr.message.operationFailed);
    }
  };

  return (
    <div>
      <h6 className="fs-6 mb-4 border-bottom pb-1">
        Télécharger le document ({doc.label})
      </h6>

      <label htmlFor="files">Télécharger des fichiers</label>
      <CFormInput
        name="files"
        id="files"
        type="file"
        accept="image/png,image/jpeg"
        // value={files as any}
        onChange={onFileUpload}
      />
      {files ? (
        <div className="mt-2">
          <img
            src={URL.createObjectURL(files[0])}
            alt=""
            className="rounded-md"
            width={180}
            height={130}
          />
        </div>
      ) : null}

      {doc.showExpirationField && (
        <div className="mt-3">
          <label htmlFor="date" className="d-block mb-1">
            Date d&apos;expiration
          </label>
          <CFormInput
            type="date"
            onChange={e => onDateChange(new Date(e.target.value))}
          />
        </div>
      )}

      <div className="mt-4">
        <button
          className="btn btn-success shadow-secondary rounded-sm p-2 mb-1 text-sm text-white"
          type="button"
          onClick={onDocumentSave}
          disabled={uploadFileMutation.isLoading}
        >
          {uploadFileMutation.isLoading
            ? "Enregistrement des documents..."
            : "Télécharger des documents"}
        </button>
      </div>
    </div>
  );
}
