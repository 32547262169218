import React, { useEffect, useState } from "react";
import { Periods } from "../helpers/enums";
import { getDateRange } from "../helpers/general";

const filters = [
  {
    label: "Dernier",
    key: "latest",
    value: "latest"
  },
  {
    label: "Aujourd'hui",
    key: Periods.today,
    value: Periods.today
  },
  {
    label: "Derniers 7 jours",
    key: Periods.last7Days,
    value: Periods.last7Days
  },
  {
    label: "Mois en cours",
    key: Periods.currentMonth,
    value: Periods.currentMonth
  },
  {
    label: "Derniers 3 mois",
    key: Periods.last3Months,
    value: Periods.last3Months
  }
];

type DateFilterProps = {
  onChange: (dates: Dates) => void;
  label?: string;
};
export default function DateFilters(props: DateFilterProps) {
  const { onChange, label } = props;
  const [selectedDate, onSelectDate] = useState<string | null>(null);

  useEffect(() => {
    if (selectedDate !== "latest" && selectedDate) {
      const dateRange = getDateRange(selectedDate as Periods);
      onChange({
        from: dateRange[0].toISOString(),
        to: dateRange[1].toISOString()
      });
    } else {
      onChange({
        from: "",
        to: ""
      });
    }
  }, [selectedDate]);

  return (
    <div>
      {label && <label htmlFor="filter">{label}</label>}
      <select
        name="date_filter"
        id="filter"
        onChange={e => onSelectDate(e.target.value)}
      >
        {filters.map(filter => (
          <option value={filter.value} key={filter.value}>
            {filter.label}
          </option>
        ))}
      </select>
    </div>
  );
}
