import {
  CFormCheck,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow
} from "@coreui/react";
import { useEffect, useState } from "react";
import { colors } from "../../config/theme";
import DeleteIcon from "../../components/icons/DeleteIcon";
import PromptModal from "../../components/modals/PromptModal";
import { formatDateString, isDateApproaching } from "../../helpers/general";
import { DELETE_ACTIONS } from "../../config/constants";
import { Link } from "react-router-dom";
import { useDriverQuery } from "../../hooks/drivers/useDriverQuery";
import { DriverProfileStatusDisplayValue } from "../../helpers/enums";

interface TableBodyProps {
  driver: Driver;
  deletedList: React.Dispatch<BulkDeleteActions>;
}

function isDocumentExpiring(driver: Driver) {
  let carteCinExpiration = driver?.driverProfile?.carteCinExpiration;
  let permisConfianceExpiration =
    driver?.driverProfile?.permisConfianceExpiration;
  let carteConducteurExpiration =
    driver?.driverProfile?.carteConducteurExpiration;
  let permisExpiration = driver?.driverProfile?.permisExpiration;

  const carteGriseExpiration =
    driver?.driverProfile?.vehicle?.carteGriseExpiration;

  const docs = [
    carteGriseExpiration,
    carteCinExpiration,
    permisConfianceExpiration,
    carteConducteurExpiration,
    permisExpiration
  ].filter(doc => doc !== null);

  const isExpiring =
    docs.length > 0
      ? docs.some(doc => isDateApproaching(new Date(doc), 30))
      : false;
  return isExpiring;
}

function TableBodyRowContent({ driver, deletedList }: TableBodyProps) {
  const [isChecked, setIsChecked] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { removeDriver } = useDriverQuery();

  useEffect(() => {
    if (isChecked) {
      deletedList({ type: DELETE_ACTIONS.ADD, id: driver.id });
    } else {
      deletedList({ type: DELETE_ACTIONS.REMOVE, id: driver.id });
    }
  }, [deletedList, isChecked, driver.id]);

  const status = driver?.driverProfile?.status.toUpperCase() as keyof typeof DriverProfileStatusDisplayValue;

  const isExpiring = isDocumentExpiring(driver);

  return (
    <CTableRow
      style={{
        borderLeft: isChecked ? `3px solid ${colors.primary}` : "",
        width: "100%",
        position: "relative"
      }}
    >
      <CTableDataCell className="px-3 text-sm">
        <div className="d-flex align-items-center gap-3">
          <CFormCheck
            id="flexCheckDefault"
            label=""
            defaultChecked={isChecked}
            onChange={e => setIsChecked(e.target.checked)}
            color={colors.yellow}
          />
          <img
            src={
              driver.profilePicture ??
              `https://ui-avatars.com/api/?name=${driver?.fullName}`
            }
            alt=""
            width={30}
            height={30}
            style={{ borderRadius: "100%" }}
          />

          <div>
            <Link to={`/drivers/${driver?.id}`} className="fw-bold m-0">
              {driver?.fullName}
            </Link>
            {/* <p className="fw-bold m-0">{driver?.fullName}</p> */}
            <p className="m-0">{driver.email}</p>
            {isExpiring && (
              <p className="text-red fw-semibold">
                Certains documents sont sur le point d&apos;expirer.
              </p>
            )}
          </div>
        </div>
      </CTableDataCell>
      <CTableDataCell className="p-3 text-sm">
        <div className="d-flex align-items-center gap-3">
          {/* <CFormCheck
            id="flexCheckDefault"
            label=""
            defaultChecked={isChecked}
            onChange={e => setIsChecked(e.target.checked)}
            color={colors.yellow}
          /> */}
          {formatDateString(driver.createdAt)}
        </div>
      </CTableDataCell>
      <CTableDataCell className="p-3 text-sm">
        {driver?.phone === null || driver?.phone === "null"
          ? ""
          : driver?.phone}
      </CTableDataCell>
      <CTableDataCell className="p-3 text-sm">
        {driver?.isVerifiedPhone ? "Oui" : "Non"}
      </CTableDataCell>
      <CTableDataCell className="p-3 text-sm">
        {DriverProfileStatusDisplayValue[status]}
      </CTableDataCell>
      <CTableDataCell className="p-3 text-sm">
        {driver?.birthday ? formatDateString(driver?.birthday) : ""}
      </CTableDataCell>
      <CTableDataCell className="p-3 text-sm">
        {driver?.city?.label ?? ""}
      </CTableDataCell>
      <CTableDataCell className="p-3  align-items-center justify-content-center ">
        <button
          onClick={() => setShowModal(true)}
          className="outline-none border-0 bg-transparent"
        >
          <DeleteIcon
            width="20"
            height="20"
            fill={colors.primary}
            className="ms-2"
          />
        </button>
      </CTableDataCell>
      <PromptModal
        open={showModal}
        onAccept={() => removeDriver(driver?.id)}
        onClose={() => setShowModal(false)}
      />
    </CTableRow>
  );
}

interface TableProps {
  drivers: Driver[];
  DriversTableToBeDeleted: React.Dispatch<BulkDeleteActions>;
}
export default function DriversTable({
  drivers,
  DriversTableToBeDeleted
}: TableProps) {
  return (
    <div className="mt-3">
      <CTable>
        <CTableHead>
          <CTableRow>
            <CTableHeaderCell scope="col" className="text-sm">
              <div className="d-flex align-items-center justify-content-between">
                Nom{" "}
              </div>
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-sm ">
              <div className="d-flex align-items-center justify-content-between">
                Date d&apos;inscription{" "}
              </div>
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-sm">
              <div className="d-flex align-items-center justify-content-between">
                Téléphone{" "}
              </div>
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-sm">
              <div className="d-flex align-items-center justify-content-between">
                Téléphone Vérifié{" "}
              </div>
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-sm">
              <div className="d-flex align-items-center justify-content-between">
                Statut
              </div>
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-sm">
              <div className="d-flex align-items-center justify-content-between">
                Date de naissance{" "}
              </div>
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-sm">
              <div className="d-flex align-items-center justify-content-between">
                Ville{" "}
              </div>
            </CTableHeaderCell>
            <CTableHeaderCell
              scope="col"
              className="text-sm"
            ></CTableHeaderCell>
          </CTableRow>
        </CTableHead>
        <CTableBody>
          {drivers?.map(driver => (
            <TableBodyRowContent
              key={driver.id}
              driver={driver}
              deletedList={DriversTableToBeDeleted}
            />
          ))}
        </CTableBody>
      </CTable>
    </div>
  );
}
