import React, { useEffect, useRef } from "react";
import { useRideChats } from "../../../../hooks/useRideChats";
import { useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

export default function RideConversations() {
  const params = useParams();
  const { chats, loadingConversation } = useRideChats(params?.rideId!);

  return (
    <div className="px-3 px-lg-5 mt-5 mx-auto chat-ui">
      <div className="rounded-tr-md rounded-tl-md">
        <Header chat={chats[0]} loading={loadingConversation} />
      </div>
      <div className=" p-3 py-4 chats-wrapper bg-white">
        <ChatUI chats={chats} loading={loadingConversation} />
      </div>
    </div>
  );
}

const ChatUI = ({
  chats,
  loading
}: {
  chats: RideConversation[];
  loading: boolean;
}) => {
  const chatEndRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [chats]);

  if (loading) {
    return (
      <div className="chat-window">
        <div className="d-flex mb-1 justify-content-end">
          <Skeleton height={50} width={130} count={2} />
        </div>
        <div className="d-flex mb-1 justify-content-start">
          <Skeleton height={50} width={130} count={2} />
        </div>
      </div>
    );
  }

  return (
    <div className="chat-window">
      {chats.map(msg => (
        <div
          key={msg.id}
          className={`d-flex mb-3 ${
            msg.sender === "user"
              ? "justify-content-end"
              : "justify-content-start"
          }`}
        >
          {msg.sender === "driver" && (
            <div className="me-2">
              <img
                src={
                  msg.driver?.profilePicture ??
                  `https://ui-avatars.com/api/?name=${msg.driver?.fullName}`
                }
                alt={msg.driver.fullName?.charAt(0)?.toUpperCase()}
                className="rounded-circle"
                width="20"
                height="20"
              />
            </div>
          )}
          <div
            className={`p-2 rounded-sm chat-bubble ${
              msg.sender === "user" ? "bg-primary-shade" : "bg-light text-dark"
            }`}
          >
            <p className="mb-0">{msg.data}</p>
            <small className="text-muted">
              {new Date(msg.dateTime).toLocaleTimeString()}
            </small>
          </div>
          {msg.sender === "user" && (
            <div className="ms-2">
              <img
                src={
                  msg.user?.profilePicture ??
                  `https://ui-avatars.com/api/?name=${msg.user?.fullName}`
                }
                alt={msg.driver.fullName?.charAt(0)?.toUpperCase()}
                className="rounded-circle"
                width="20"
                height="20"
              />
            </div>
          )}
        </div>
      ))}
      {chats.length == 0 && (
        <div className="fw-semibold d-flex justify-content-center align-content-center h-full w-full">
          Aucune conversation à afficher
        </div>
      )}
      <div ref={chatEndRef} />
    </div>
  );
};

interface HeaderProps {
  chat: RideConversation;
  loading: boolean;
}
const Header = ({ chat, loading }: HeaderProps) => {
  const driver = chat?.driver;
  const user = chat?.user;
  const destination = chat?.destinationLocation;
  const pickupLocation = chat?.pickupLocation;

  const noParticipants = !driver?.fullName || !user?.fullName;

  const HeaderLoader = () => (
    <>
      <Skeleton className="rounded-full" width={35} height={35} />
      <div>
        <Skeleton count={2} height={15} width={250} />
      </div>
    </>
  );

  const content = !noParticipants ? (
    <>
      <div className="d-flex align-items-center">
        <img className="me-1"
          src={
            driver?.profilePicture ??
            `https://ui-avatars.com/api/?name=${driver?.fullName}`
          }
          alt=""
          width={30}
          height={30}
          style={{ borderRadius: "100%" }}
        />
        <img className="ms-1"
          src={
            user?.profilePicture ??
            `https://ui-avatars.com/api/?name=${user?.fullName}`
          }
          alt=""
          width={30}
          height={30}
          style={{ borderRadius: "100%" }}
        />
      </div>
      <div>
        <p className="fw-medium mb-0">
          Conversation entre {driver?.fullName} et {user?.fullName}
        </p>
        <p className="text-sm text-white mb-0">
          De {destination} à {pickupLocation}
        </p>
      </div>
    </>
  ) : (
    <p className="fw-medium mb-0 text-white">Aucun participant à la conversation</p>
  );

  return (
    <div
      className="chat-ui-header rounded-tl-md rounded-tr-md gap-3 bg-gradient-primary p-3"
      style={{ alignSelf: "flex-start" }}
    >
      {!loading ? content : <HeaderLoader />}
    </div>
  );
};
