import React, { useEffect, useRef, useState } from 'react';

import { MapContainer, TileLayer, Marker, Polyline, useMap } from "react-leaflet";


import AdminRoute from "../../../auth/RestrictedRoute";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCar, faCommentAlt, faCommentDots, faCommentNodes, faComments, faCommentsDollar, faCommentSlash, faInfo, faSackDollar, faTaxi, faUser } from '@fortawesome/free-solid-svg-icons';



import {
  CCard,
  CCardHeader,
  CCardBody,
  CContainer,
  CBadge,

} from '@coreui/react';

import BoxWrapper from '../../../../components/BoxWrapper';
import { useLocation, useParams } from 'react-router-dom';
import { formatDateString } from '../../../../helpers/general';
import Skeleton from 'react-loading-skeleton';
import { useRideChats } from '../../../../hooks/useRideChats';
import 'leaflet-routing-machine';

import { divIcon, LatLngBounds, LatLngTuple, SVG } from 'leaflet';


const ONE_KILOMETER = 1000;
function metersToKilometers(meters: number): number {
  return meters < ONE_KILOMETER ? meters : meters / ONE_KILOMETER;
}




function decodePolyline(encoded: string): LatLngTuple[] {
  let polylineCoordinates: LatLngTuple[] = [];
  let index = 0, len = encoded.length;
  let lat = 0, lng = 0;

  while (index < len) {
    let b, shift = 0, result = 0;
    do {
      b = encoded.charCodeAt(index++) - 63;
      result |= (b & 0x1f) << shift;
      shift += 5;
    } while (b >= 0x20);
    let dlat = ((result & 1) !== 0 ? ~(result >> 1) : (result >> 1));
    lat += dlat;

    shift = 0;
    result = 0;
    do {
      b = encoded.charCodeAt(index++) - 63;
      result |= (b & 0x1f) << shift;
      shift += 5;
    } while (b >= 0x20);
    let dlng = ((result & 1) !== 0 ? ~(result >> 1) : (result >> 1));
    lng += dlng;

    polylineCoordinates.push([lat / 1E5, lng / 1E5]);
  }

  return polylineCoordinates;
}


function FitBounds({ bounds }: { bounds: LatLngBounds }) {
  const map = useMap();
  useEffect(() => {
    map.fitBounds(bounds);
  }, [map, bounds]);
  return null;
}




function RideDetailsPage() {


  const params = useParams();
  const { chats, loadingConversation } = useRideChats(params?.rideId!);
  const location = useLocation();
  const { ride }: { ride: Ride } = location.state || {};



  const [route, setRoute] = useState<LatLngTuple[]>([]);


  useEffect(() => {
    const encodedPath = ride.suggestedRoute
    const decodedPath = decodePolyline(encodedPath);
    setRoute(decodedPath);
  }, []);


  const startIconSvg = `
      <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.0729 27.1293C21.3367 27.1293 27.2252 21.2409 27.2252 13.977C27.2252 6.7132 21.3367 0.824707 14.0729 0.824707C6.80903 0.824707 0.920532 6.7132 0.920532 13.977C0.920532 21.2409 6.80903 27.1293 14.0729 27.1293Z" fill="white"/>
    <path d="M14.073 23.8413C19.5208 23.8413 23.9372 19.4249 23.9372 13.977C23.9372 8.52916 19.5208 4.11279 14.073 4.11279C8.62511 4.11279 4.20874 8.52916 4.20874 13.977C4.20874 19.4249 8.62511 23.8413 14.073 23.8413Z" stroke="url(#paint0_linear_1719_23049)" stroke-width="6.57616"/>
    <defs>
    <linearGradient id="paint0_linear_1719_23049" x1="4.6327" y1="5.3033" x2="24.415" y2="5.82797" gradientUnits="userSpaceOnUse">
    <stop stop-color="#FF7722"/>
    <stop offset="1" stop-color="#FFB800"/>
    </linearGradient>
    </defs>
    </svg>

    `;


  const endIconSvg =
    `
      <svg width="21" height="27" viewBox="0 0 21 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.3215 26.4107C9.27921 26.4107 4.32736 20.2893 3.11406 18.3198C1.30452 15.3759 0.208496 13.5981 0.208496 10.2191C0.208496 7.53676 1.27403 4.96433 3.1707 3.06767C5.06736 1.171 7.63979 0.105469 10.3221 0.105469C13.0044 0.105469 15.5768 1.171 17.4735 3.06767C19.3701 4.96433 20.4357 7.53676 20.4357 10.2191C20.4357 13.4622 19.355 15.2136 17.5652 18.117L17.4413 18.3187C16.1908 20.3452 11.3441 26.4107 10.3215 26.4107ZM10.3215 5.16308C9.32134 5.16308 8.34361 5.45968 7.51198 6.01535C6.68035 6.57103 6.03217 7.36084 5.64941 8.28489C5.26666 9.20895 5.16651 10.2258 5.36164 11.2067C5.55676 12.1877 6.0384 13.0888 6.74565 13.796C7.45289 14.5033 8.35397 14.9849 9.33495 15.18C10.3159 15.3752 11.3327 15.275 12.2568 14.8923C13.1808 14.5095 13.9707 13.8613 14.5263 13.0297C15.082 12.1981 15.3786 11.2203 15.3786 10.2201C15.3771 8.87938 14.8439 7.59394 13.8958 6.64587C12.9477 5.6978 11.6623 5.16453 10.3215 5.16308Z" fill="#22AAFF"/>
      </svg>
    `;



  const startIcon = divIcon({
    html: startIconSvg,
    className: '',
    iconSize: [21, 27],
    iconAnchor: [10.5, 27],
  });

  const endIcon = divIcon({
    html: endIconSvg,
    className: '',
    iconSize: [21, 27],
    iconAnchor: [10.5, 27],
  });

  const statusMapping: { [key: string]: string } = {
    completed: 'Terminé',
    pending: 'En attente',
    user_cancelled: 'Annulé par l\'utilisateur',
    driver_cancelled: 'Annulé par le conducteur',
    no_driver_found: 'Aucun conducteur trouvé',

  };

  const displayStatus = statusMapping[ride.status] || ride.status;


  return (


    <AdminRoute>
      <section className='bg-blue'>
        <h5 className="fs-4 fw-bold mb-2">Détails du trajet</h5>
        <BoxWrapper className="bg-black" >
          <CContainer fluid>
            {/* Header */}
            <div className="mb-4 ">
              <div className="d-flex align-items-center gap-2 pt-2">
                <CBadge className={ride.status !== "completed" ? "bg-red p-2" : "bg-success p-2  "} >
                  {displayStatus}</CBadge>
                <span className="text-medium-emphasis ">{formatDateString(ride.createdAt)}</span>
              </div>
            </div>


            {/* Left Column - Map and Route */}

            <CCard className="mb-4">
              <CCardHeader className='bg-gradient-primary'>
                <div className="d-flex align-items-center text-white">
                  {/* <CIcon icon={cilLocationPin} className="me-2" /> */}
                  <FontAwesomeIcon icon={faCar} className="me-2" />
                  Informations sur le trajet
                </div>
              </CCardHeader>
              <CCardBody>
                <div className="bg-light  text-center mb-4" style={{ height: '400px' }}>
                  <MapContainer
                    center={[30.427755, -9.598107]} // Default center
                    zoom={13}
                    style={{ height: "100%", width: "100%", zIndex: 40 }}
                  >
                    <TileLayer
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      attribution="&copy; <a href='https://www.openstreetmap.org/copyright'>OpenStreetMap</a> contributors"
                    />
                    {route.length > 0 && (
                      <>
                        <Polyline positions={route} color="#FF7722" weight={3} />
                        <Marker position={route[0]} icon={startIcon} />
                        <Marker position={route[route.length - 1]} icon={endIcon} />

                      </>
                    )}


                  </MapContainer>
                </div>
                <div className="mb-3">
                  <span dangerouslySetInnerHTML={{ __html: startIconSvg }} style={{ marginRight: '2px' }} />
                  <small className="text-medium-emphasis"> Point de départ </small>
                  <div className="fw-medium">{ride.pickupLocationName}</div>
                </div>
                <div>
                  <span dangerouslySetInnerHTML={{ __html: endIconSvg }} style={{ marginRight: '2px' }} />
                  <small className="text-medium-emphasis">Point destination</small>
                  <div className="fw-medium">{ride.destinationLocationName}</div>
                </div>
              </CCardBody>
            </CCard>



            <div className="d-flex gap-4  ride-table-info-wrapper " style={{ maxHeight: '50vh', overflowY: 'auto' }} >
              {/* Trip Summary */}
              <CCard className="mb-4 flex-grow-1" style={{ flex: 1 }}>

                <CCardHeader className='bg-gradient-primary text-white'>
                  <FontAwesomeIcon icon={faInfo} className="me-2" />
                  Informations sur le trajet</CCardHeader>

                <CCardBody>
                  <div className="d-flex justify-content-between mb-2">
                    <div className="d-flex align-items-center text-bold " style={{ fontSize: '1.1rem' }}>

                      Duration
                    </div>
                    <span className="text-bold " style={{ fontSize: '1.1rem' }}>{ride.estimatedDuration ? Math.floor(ride.estimatedDuration / 60) : ""} min</span>
                  </div>
                  <div className="d-flex justify-content-between mb-2">
                    <div className="d-flex align-items-center text-bold " style={{ fontSize: '1.1rem' }}>

                      Distance
                    </div>
                    <span className="text-bold " style={{ fontSize: '1.1rem' }}>{metersToKilometers(ride.distance).toFixed(2)}{" "}
                      {ride.distance < ONE_KILOMETER ? "m" : "Km"}</span>
                  </div>
                  <div className="d-flex justify-content-between mb-2">
                    <div className="d-flex align-items-center text-bold " style={{ fontSize: '1.1rem' }}>

                      Prix
                    </div>
                    <span className="text-bold " style={{ fontSize: '1.1rem' }}>{ride.fare} MAD</span>
                  </div>
                  <div className="d-flex justify-content-between mb-2">
                    <small className="d-flex align-items-center text-bold " style={{ fontSize: '1.1rem' }}> Mode de paiement </small>
                    <span className="text-bold " style={{ fontSize: '1.1rem' }}>{ride.paymentMethod}</span>
                  </div>
                </CCardBody>

                <CCardHeader className='bg-gradient-primary text-white'>
                  <FontAwesomeIcon icon={faSackDollar} className="me-2" />
                  Informations sur le prelevement
                </CCardHeader>
                < CCardBody>

                  <div >
                    <div className='d-flex justify-content-between mb-2'>
                      <div className="d-flex align-items-center text-bold " style={{ fontSize: '1.1rem' }}>

                        Total commission
                      </div>
                      <span className="text-bold " style={{ fontSize: '1.1rem' }}>
                        {ride.status === "completed" ? (ride.priceCalculation.calculation.balanceCommission) + (ride.priceCalculation.calculation.balanceTVA) : "0"}
                        MAD</span>
                    </div>
                    <div className='d-flex justify-content-between mb-2'>
                      <div className="d-flex align-items-center text-bold " style={{ fontSize: '1.1rem' }}>

                        Frais de service
                      </div>
                      <span className="text-bold " style={{ fontSize: '1.1rem' }}>
                        {ride.status === "completed" ? ride.priceCalculation.calculation.balanceCommission : "0"}
                        MAD</span>
                    </div>
                    <div className='d-flex justify-content-between mb-2'>
                      <div className="d-flex align-items-center text-bold " style={{ fontSize: '1.1rem' }}>

                        TVA
                      </div>
                      <span className="text-bold " style={{ fontSize: '1.1rem' }}>
                        {ride.status === "completed" ? ride.priceCalculation.calculation.balanceTVA : "0"}
                        MAD</span>
                    </div>
                  </div>


                </CCardBody>
              </CCard>

              {/* Driver Info */}
              <CCard className="mb-4 flex-grow-1 " style={{ flex: 1 }}>
                <CCardHeader className='bg-gradient-primary'>
                  <div className="d-flex align-items-center text-white">
                    <FontAwesomeIcon icon={faTaxi} className="me-2" />
                    Informations sur le conducteur
                  </div>
                </CCardHeader>

                {ride.status === "no_driver_found" ? (
                  <CCardBody>
                    <div className="d-flex justify-content-center">
                      <div className="text-center">
                        <div className="fw-semibold text-center d-flex flex-column justify-content-center align-content-center">Aucun conducteur trouvé</div>
                        <FontAwesomeIcon icon={faUser} className="text-5  custom-icon" />
                      </div>
                    </div>
                  </CCardBody>
                ) : (
                  <CCardBody>
                    <div className="d-flex align-items-center gap-3 mb-3">
                      <div className="rounded-circle">
                        <div className="">
                          <img
                            src={
                              ride.driver?.profilePicture ??
                              `https://ui-avatars.com/api/?name=${ride.driver?.profilePicture}`
                            }
                            alt={ride.driver?.fullName?.charAt(0)?.toUpperCase()}
                            className="rounded-circle"
                            width="70"
                            height="70"
                          />
                        </div>
                      </div>
                      <div>
                        <div className="small">
                          <div className="mb-2 text-5 font-bold" style={{ fontSize: '1.1rem' }}>
                            <span className="text-medium-emphasis">Nom:</span> {ride.driver?.fullName}
                          </div>
                          <div className="mb-2 text-5 font-bold" style={{ fontSize: '1.1rem' }}>
                            <span className="text-medium-emphasis">E-mail:</span> {ride.driver?.email}
                          </div>
                          <div className="mb-2 text-5 font-bold" style={{ fontSize: '1.1rem' }}>
                            <span className="text-medium-emphasis">Télephone:</span> {ride.driver?.phone}
                          </div>
                          <div className="mb-2 text-5 font-bold" style={{ fontSize: '1.1rem' }}>
                            <span className="text-medium-emphasis">Matricule:</span> {ride.driver?.driverProfile?.vehicle?.immatriculation}
                          </div>
                          <div className="mb-2 text-5 font-bold" style={{ fontSize: '1.1rem' }}>
                            <span className="text-medium-emphasis">Rating:</span> ⭐ {ride.driver?.rating}
                          </div>
                        </div>
                      </div>
                    </div>
                  </CCardBody>
                )}




                <CCardHeader className='bg-gradient-primary text-white'>
                  <FontAwesomeIcon icon={faUser} className="me-2" />
                  Informations sur le passager
                </CCardHeader>


                <CCardBody>
                  <div className="d-flex align-items-center gap-3 mb-3">
                    <div className="rounded-circle">
                      <div className="">
                        <img
                          src={
                            ride.user?.profilePicture ??
                            `https://ui-avatars.com/api/?name=${ride.user.profilePicture}`
                          }
                          alt={ride.user?.fullName?.charAt(0)?.toUpperCase()}
                          className="rounded-circle"
                          width="70"
                          height="70"
                        />
                      </div>
                    </div>
                    <div>



                      <div className="small">
                        <div className="mb-2 text-5 font-bold" style={{ fontSize: '1.1rem' }}>
                          <span className="text-medium-emphasis">Nom:</span> {ride.user?.fullName}
                        </div>
                        <div className="mb-2 text-5 font-bold" style={{ fontSize: '1.1rem' }}>
                          <span className="text-medium-emphasis">Email:</span> {ride.user?.email}
                        </div>
                        <div className="mb-2 text-5 font-bold" style={{ fontSize: '1.1rem' }}>
                          <span className="text-medium-emphasis">Phone:</span> {ride.user?.phone}
                        </div>
                      </div>

                    </div>
                  </div>

                </CCardBody>
              </CCard>


              <CCard className="mb-4 flex-grow-1 max-w-200" style={{ flex: 1 }}>
                <CCardHeader className='bg-gradient-primary'>
                  <div className="d-flex align-items-center text-white">
                    <FontAwesomeIcon icon={faComments} className="me-2" />
                    Conversations
                  </div>
                </CCardHeader>



                <div className=" p-2 chats-wrapper-full bg-white">
                  <ChatUI chats={chats} loading={loadingConversation} />
                </div>

              </CCard>

            </div>

          </CContainer>

        </BoxWrapper>

      </section>
    </AdminRoute>
  );
};



const ChatUI = ({
  chats,
  loading
}: {
  chats: RideConversation[];
  loading: boolean;
}) => {
  const chatEndRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [chats]);

  if (loading) {
    return (
      <div className="chat-window">
        <div className="d-flex mb-1 justify-content-end">
          <Skeleton height={50} width={130} count={2} />
        </div>
        <div className="d-flex mb-1 justify-content-start">
          <Skeleton height={50} width={130} count={2} />
        </div>
      </div>
    );
  }

  return (
    <div className="chat-window">
      {chats.map(msg => (
        <div
          key={msg.id}
          className={`d-flex mb-3 ${msg.sender === "user"
            ? "justify-content-end"
            : "justify-content-start"
            }`}
        >
          {msg.sender === "driver" && (
            <div className="me-2">
              <img
                src={
                  msg.driver?.profilePicture ??
                  `https://ui-avatars.com/api/?name=${msg.driver?.fullName}`
                }
                alt={msg.driver.fullName?.charAt(0)?.toUpperCase()}
                className="rounded-circle"
                width="30"
                height="30"
              />
            </div>
          )}
          <div
            className={`p-3 rounded-sm chat-bubble ${msg.sender === "user" ? "bg-primary-shade" : "bg-light text-dark"
              }`}
          >
            <p className="mb-1">{msg.data}</p>
            <small className="text-muted">
              {new Date(msg.dateTime).toLocaleTimeString()}
            </small>
          </div>
          {msg.sender === "user" && (
            <div className="ms-2">
              <img
                src={
                  msg.user?.profilePicture ??
                  `https://ui-avatars.com/api/?name=${msg.user?.fullName}`
                }
                alt={msg.driver.fullName?.charAt(0)?.toUpperCase()}
                className="rounded-circle"
                width="30"
                height="30"
              />
            </div>
          )}
        </div>
      ))}
      {chats.length == 0 && (
        <div className="fw-semibold text-center d-flex flex-column justify-content-center align-content-center h-full w-full">
          Aucune conversation à afficher
          <div className='text-center'>
            <FontAwesomeIcon icon={faComments} className="me-2 custom-icon" />
          </div>
        </div>
      )}
      <div ref={chatEndRef} />
    </div>
  );
};

export default RideDetailsPage;