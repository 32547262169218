import { CFormInput, CSpinner } from "@coreui/react";
import React from "react";
import { useMutation } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import { FormEvent } from "react";
import BoxWrapper from "../../../components/BoxWrapper";
import apiService from "../../../service/apiService";
import { general } from "../../../locales/general";
import { useCookie } from "../../../hooks/useCookie";
import { useToast } from "../../../hooks/useToast";
import RestrictedRoute from "../../auth/RestrictedRoute";

interface NewCategory {
  label: string;
  order: number;
  picture: File;
}

interface Props {
  onClose: () => void;
  onAccept: () => void;
}

export default function NewCategory({ onClose }: Props) {
  const { toastSuccess, toastError } = useToast();
  const { token } = useCookie("vToken");
  const navigate = useNavigate();
  // @desc create new user
  const createNewCategoryMutation = useMutation(async (data: NewCategory) => {
    return await apiService.MakePostRequest("categories", data, token, true);
  });

  // @desc form submit handler
  const createCategoryHandler = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const label = formData.get("label") as string;
    const order = formData.get("order") as unknown as number;
    const picture = formData.get("image") as File;

    const data = {
      label,
      order,
      picture
    };
    createNewCategoryMutation.mutate(data, {
      onSuccess(data: any) {
        navigate("/categories");
        toastSuccess(general.fr.message.categoryCreated);
      },
      onError(error: any) {
        toastError(general.fr.message.operationFailed);
      }
    });
  };

  return (
    <RestrictedRoute>
      <div className=" mt-4 ">
        <BoxWrapper>
          <section className="p-4">
            <div className="border-bottom border-success">
              <h4>Ajouter une catégorie</h4>
            </div>
            <form className="row form mt-4" onSubmit={createCategoryHandler}>
              <div className="col-md-6 mt-4">
                <label htmlFor="label" className="d-block">
                  Nom de la catégorie
                </label>
                <input
                  type="text"
                  className="custom-input"
                  placeholder=" Nom de la catégorie"
                  id="label"
                  name="label"
                  required
                />
                <div className="md-6 mt-4">
                <label htmlFor="order" className="d-block">
                  Ordre de la catégorie
                </label>
                <input
                  type="number"
                  className="custom-input"
                  placeholder=" Ordre de la catégorie"
                  id="order"
                  name="order"
                  required
                />
                </div>

              </div>

              

              <div className="col-md-6 mt-4">
                <label htmlFor="image" className="d-block">
                  Image de catégorie
                </label>
                <CFormInput
                  type="file"
                  id="image"
                  name="image"
                  className="custom-input"
                  required
                  accept="image/png,image/jpeg,image/jpg"
                />
              </div>

              <section className="d-flex justify-content-center buttons gap-4 mt-5">
                <button
                  className="btn btn-danger shadow-primary w-20 px-4 py-2 text-white"
                  type="button"
                  onClick={onClose}
                >
                  Annuler
                </button>
                <button
                  className="btn btn-success shadow-secondary w-20 text-white"
                  type="submit"
                  disabled={createNewCategoryMutation.isLoading}
                >
                  {createNewCategoryMutation.isLoading ? (
                    <div className="text-center">
                      <CSpinner size="sm" />
                    </div>
                  ) : (
                    "Enregistrer"
                  )}
                </button>
              </section>
            </form>
          </section>
        </BoxWrapper>
      </div>
    </RestrictedRoute>
  );
}
