export const FormMode = {
  CREATE: "create",
  UPDATE: "update"
};

export enum OFFER_TYPES {
  SPECIAL = "SPECIAL_OFFER",
  TOUR = "TOUR_OFFER",
  EVENT = "EVENT_OFFER"
}

export const enum BookingStatus {
  WAITING = "waiting",
  CONFIRMED = "confirmed",
  CANCELED = "canceled",
  REFUSED = "refused",
  PASSED = "passed",
  CASH_PRE_PAYMENT = "cash_pre_payment"
}

export enum BookingStatusDisplayValue {
  WAITING = "En attente",
  CONFIRMED = "Confirmée",
  CANCELED = "Annulée",
  REFUSED = "Refusée",
  PASSED = "Passée",
  CASH_PRE_PAYMENT = "En attente de paiement"
}

export enum PaymentOptions {
  IN_PLACE = "in_place",
  CREDIT_CARD = "credit_card",
  WAFACASH = "wafacash",
  STRIPE = "stripe"
}

export enum BookingTypes {
  PLACE_BOOKING = "PLACE_BOOKING",
  EVENT_BOOKING = "EVENT_BOOKING",
  TOUR_BOOKING = "TOUR_BOOKING",
  SPECIAL_OFFER_BOOKING = "SPECIAL_OFFER_BOOKING",
  PACK_BOOKING = "PACK_BOOKING"
}

export enum DriverProfileStatus {
  IN_REVIEW = "in_review",
  APPROVED = "approved",
  DECLINED = "declined",
  BLOCKED = "blocked"
}

export enum DriverProfileStatusDisplayValue {
  IN_REVIEW = "En attente",
  APPROVED = "Acceptée",
  DECLINED = "Refusée",
  BLOCKED = "Bloquee"
}

export enum RideStatus {
  SEARCHING_FOR_DRIVERS = "searching_for_drivers",
  NO_DRIVER_FOUND = "no_driver_found",
  WAITING_PICKUP = "waiting_pickup",
  ON_GOING = "on_going",
  DRIVER_CANCELLED = "driver_cancelled",
  USER_CANCELLED = "user_cancelled",
  COMPLETED = "completed"
}

export const RideStatusLabels = {
  [RideStatus.SEARCHING_FOR_DRIVERS]: "Recherche de conducteurs",
  [RideStatus.NO_DRIVER_FOUND]: "Aucun conducteur trouvé",
  [RideStatus.WAITING_PICKUP]: "En attente de prise en charge",
  [RideStatus.ON_GOING]: "En cours",
  [RideStatus.DRIVER_CANCELLED]: "Conducteur annulé",
  [RideStatus.USER_CANCELLED]: "Client annulé",
  [RideStatus.COMPLETED]: "Terminé"
};

export enum TransactionType {
  TOP_UP = "top_up",
  RIDE_PRECETANGE = "ride_precetange",
  VAT = "vat",
  REFUND = "refund",
  VENEZ_TOP_UP = "venez_top_up"
}

export const TransactionTypeLabel = {
  [TransactionType.TOP_UP]: "Recharge",
  [TransactionType.RIDE_PRECETANGE]: "Pourcentage de course",
  [TransactionType.VAT]: "TVA",
  [TransactionType.REFUND]: "Remboursement",
  [TransactionType.VENEZ_TOP_UP]: "Venez recharge"
};

export enum TransactionStatus {
  COMPLETED = "completed",
  WAITING_FOR_PAYMENT = "waiting_for_payment",
  CANCELLED = "cancelled"
  // PENDING = 'pending',
}

export const TransactionStatusLabel = {
  [TransactionStatus.COMPLETED]: "Terminé",
  [TransactionStatus.WAITING_FOR_PAYMENT]: "En attente de paiement",
  [TransactionStatus.CANCELLED]: "Annulée"
};

export enum TranscationPaymentMethods {
  LOCAL = "local",
  WAFACASH = "wafacash"
}

export enum Periods {
  currentMonth = "currentMonth",
  last3Months = "last3Months",
  last6Months = "last6Months",
  currentYear = "currentYear",
  lastYear = "lastYear",
  twoYearsAgo = "2YearsAgo",
  last7Days = "last7Days",
  today = "today"
}
