import { useState } from "react";
import { useQuery } from "react-query";
import apiService from "../service/apiService";
import { apiConfig } from "../config/apiConfig";
import { useCookie } from "./useCookie";
import { RideStatus } from "../helpers/enums";

type Query = {
  query: string;
  page: number;
  status?: RideStatus;
  paymentMethod?: "cash";
  startDate?: string;
  endDate?: string;
};

type RideResponse = {
  items: Ride[];
  meta: PaginationMeta;
};

export function useRides() {
  const [queries, setQueries] = useState<Query>({
    query: "",
    page: 1,
    status: undefined,
    paymentMethod: undefined,
    startDate: undefined,
    endDate: undefined
  });

  const endpoint = apiConfig.rides.all(queries);
  const { token } = useCookie("vToken");

  const { query, page, status, paymentMethod, startDate, endDate } = queries;
  const deps = [
    "rides",
    query,
    page,
    status,
    paymentMethod,
    startDate,
    endDate,
    endpoint
  ];

  const { data, isLoading } = useQuery<RideResponse>(deps, () => {
    return apiService.MakeGetRequest(endpoint, token);
  });

  const addQuery = <K extends keyof typeof queries>(
    key: K,
    value: typeof queries[K]
  ) => {
    const page = key === "page" ? (value as number) : 1;
    setQueries(prev => ({ ...prev, [key]: value, page }));
  };
  const setDates = (dates: Dates) => {
    setQueries(prev => ({
      ...prev,
      startDate: dates.from,
      endDate: dates.to,
      page: 1
    }));
  };

  const removeRide = async (rideId: String) => {};

  return {
    rides: data,
    isLoading,
    queries,
    addQuery,
    removeRide,
    setDates
  };
}
