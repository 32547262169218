import { Feature, MultiPolygon } from 'geojson';





export const cityBoundaries: Feature<MultiPolygon> = {
  "type": "Feature" as const,
  "properties": {
    "osm_id": 2529624,
    "name": "Agadir"
  },
  "geometry": {
    "type": "MultiPolygon",
    "coordinates": [[[
      [-9.6765238, 30.4933553],
[-9.6760422, 30.4908673],
[-9.6758531, 30.4896481],
[-9.6758686, 30.4888355],
[-9.6758961, 30.4887766],
[-9.6759483, 30.4887148],
[-9.6760999, 30.4886263],
[-9.6761147, 30.4885593],
[-9.6760596, 30.4884981],
[-9.6758331, 30.4885628],
[-9.6757774, 30.4885628],
[-9.6757359, 30.4885119],
[-9.6756761, 30.4882606],
[-9.6755674, 30.4878036],
[-9.6753582, 30.4866293],
[-9.6752671, 30.4859018],
[-9.6752671, 30.4858394],
[-9.6752778, 30.4857811],
[-9.6762238, 30.4857155],
[-9.6763507, 30.4857186],
[-9.6764673, 30.4857221],
[-9.6765451, 30.4856747],
[-9.6765894, 30.4856146],
[-9.6765827, 30.4855372],
[-9.6765129, 30.4854586],
[-9.6764392, 30.4854366],
[-9.6763694, 30.4854401],
[-9.6762399, 30.485489],
[-9.6752429, 30.4855974],
[-9.6749989, 30.4855834],
[-9.674889, 30.4855291],
[-9.6747709, 30.4853604],
[-9.6745188, 30.4848218],
[-9.674339, 30.4840937],
[-9.6742801, 30.4837031],
[-9.6742827, 30.4833078],
[-9.6743713, 30.4831356],
[-9.674384, 30.4830501],
[-9.6743806, 30.4829958],
[-9.674347, 30.4828802],
[-9.674276, 30.4825993],
[-9.6743283, 30.4823798],
[-9.6744396, 30.4822295],
[-9.6750699, 30.4817597],
[-9.6750774, 30.4817146],
[-9.6750432, 30.4816863],
[-9.6748875, 30.4817117],
[-9.6748172, 30.4816869],
[-9.6747589, 30.4816297],
[-9.6746998, 30.4814991],
[-9.6747386, 30.4811836],
[-9.6747066, 30.4808368],
[-9.6746165, 30.4804777],
[-9.6745616, 30.4800694],
[-9.6745966, 30.4797527],
[-9.6746824, 30.4795724],
[-9.6746831, 30.4795192],
[-9.6746676, 30.4794568],
[-9.674602, 30.4793251],
[-9.6744972, 30.4791969],
[-9.6743887, 30.4791575],
[-9.6743168, 30.4791768],
[-9.6742345, 30.4792476],
[-9.6741044, 30.479443],
[-9.673816, 30.4795136],
[-9.6734069, 30.4794915],
[-9.6730723, 30.4792812],
[-9.6730101, 30.479265],
[-9.6729497, 30.4792754],
[-9.6728511, 30.4793361],
[-9.6727445, 30.4793413],
[-9.6726331, 30.4793077],
[-9.6723743, 30.4791564],
[-9.6720149, 30.4788143],
[-9.6717172, 30.4784062],
[-9.6715885, 30.4781774],
[-9.6714208, 30.4778076],
[-9.6711445, 30.4771985],
[-9.6703291, 30.474873],
[-9.6703091, 30.4747423],
[-9.6703104, 30.4746024],
[-9.6703909, 30.4744776],
[-9.6704083, 30.4743378],
[-9.6703587, 30.4742395],
[-9.6700798, 30.4741447],
[-9.6699161, 30.4739136],
[-9.6697016, 30.4734697],
[-9.6695084, 30.4729126],
[-9.6693448, 30.4724017],
[-9.6691678, 30.4719717],
[-9.6690766, 30.4716712],
[-9.6690068, 30.4706218],
[-9.6686112, 30.469738],
[-9.6683484, 30.4693745],
[-9.6681485, 30.4690151],
[-9.6679647, 30.4682695],
[-9.6678695, 30.4675205],
[-9.6679125, 30.4669298],
[-9.6680304, 30.4661299],
[-9.6681291, 30.4658605],
[-9.6682196, 30.465783],
[-9.668231, 30.4657512],
[-9.6682069, 30.4657073],
[-9.6681633, 30.465694],
[-9.6680761, 30.4656905],
[-9.6680486, 30.4656813],
[-9.6680178, 30.465657],
[-9.6679957, 30.4655588],
[-9.6679903, 30.4654593],
[-9.668001, 30.4654137],
[-9.6680326, 30.4653958],
[-9.6681333, 30.465388],
[-9.6681489, 30.4653793],
[-9.6681546, 30.4653565],
[-9.6681459, 30.4653212],
[-9.6680842, 30.4651212],
[-9.668052, 30.4650605],
[-9.6680171, 30.4650178],
[-9.6679521, 30.4649658],
[-9.6679233, 30.464923],
[-9.6679031, 30.4648374],
[-9.6678991, 30.4647444],
[-9.6679065, 30.4646271],
[-9.6679393, 30.4644895],
[-9.6680218, 30.4644196],
[-9.6680988, 30.464374],
[-9.668115, 30.4643098],
[-9.6680921, 30.4642411],
[-9.6680036, 30.464189],
[-9.6678816, 30.4639936],
[-9.6678615, 30.4637555],
[-9.6677998, 30.4636237],
[-9.6676537, 30.4634712],
[-9.6674632, 30.4632018],
[-9.6673197, 30.4629278],
[-9.6671319, 30.4626504],
[-9.6669522, 30.4622828],
[-9.6667121, 30.4618943],
[-9.6665163, 30.4615302],
[-9.6664976, 30.4613799],
[-9.6665069, 30.4610956],
[-9.6664828, 30.4608379],
[-9.6664386, 30.4604008],
[-9.6665123, 30.4599939],
[-9.6665379, 30.4598968],
[-9.6666089, 30.459817],
[-9.666743, 30.4597662],
[-9.6667491, 30.4597297],
[-9.6667229, 30.4597037],
[-9.6665432, 30.4597188],
[-9.6664199, 30.4596899],
[-9.6663105, 30.4596292],
[-9.666051, 30.4593974],
[-9.6659661, 30.4591321],
[-9.6659182, 30.458846],
[-9.6659423, 30.4586691],
[-9.6660698, 30.4585697],
[-9.6660886, 30.4585188],
[-9.6660618, 30.4584656],
[-9.6659567, 30.4583137],
[-9.6659719, 30.458224],
[-9.6660362, 30.4581466],
[-9.6662012, 30.4580749],
[-9.6662427, 30.4579813],
[-9.6662196, 30.4578929],
[-9.6659728, 30.4577912],
[-9.6657667, 30.4577998],
[-9.6656246, 30.4578209],
[-9.6655421, 30.4578168],
[-9.665475, 30.4577853],
[-9.6653643, 30.4576113],
[-9.6653067, 30.4573616],
[-9.665347, 30.4572009],
[-9.6654771, 30.4571038],
[-9.665591, 30.457083],
[-9.665776, 30.4570761],
[-9.6658063, 30.4570477],
[-9.6658097, 30.4569951],
[-9.665744, 30.4569385],
[-9.6655749, 30.4569119],
[-9.6654992, 30.456853],
[-9.6654851, 30.456805],
[-9.6655004, 30.4567587],
[-9.6655662, 30.4567113],
[-9.6655669, 30.4566911],
[-9.665546, 30.4566628],
[-9.6654528, 30.4566588],
[-9.6651538, 30.4566587],
[-9.6647662, 30.4563466],
[-9.6646321, 30.4561397],
[-9.6645167, 30.4558391],
[-9.6644899, 30.4556681],
[-9.6645047, 30.4555732],
[-9.6645892, 30.4554275],
[-9.6645705, 30.4553547],
[-9.6645248, 30.455305],
[-9.6643692, 30.455194],
[-9.6641506, 30.4549304],
[-9.6641118, 30.4547327],
[-9.6641274, 30.4545182],
[-9.6641734, 30.4544368],
[-9.6641815, 30.4543327],
[-9.6641426, 30.4542218],
[-9.6640447, 30.4541524],
[-9.6638542, 30.4541466],
[-9.6637846, 30.4541189],
[-9.6637531, 30.4540708],
[-9.6637444, 30.4540044],
[-9.6637565, 30.4539513],
[-9.6638905, 30.4538992],
[-9.6638972, 30.4538495],
[-9.6638569, 30.4538195],
[-9.6635726, 30.4538414],
[-9.6634507, 30.4538137],
[-9.6633902, 30.4537536],
[-9.6632883, 30.4535154],
[-9.6632829, 30.4532749],
[-9.6632856, 30.4531038],
[-9.6633339, 30.4529836],
[-9.6634331, 30.4528402],
[-9.6635056, 30.4527223],
[-9.6634948, 30.4526691],
[-9.6633835, 30.4524876],
[-9.6631756, 30.4521281],
[-9.6627036, 30.4514668],
[-9.662658, 30.4512818],
[-9.6626687, 30.4510714],
[-9.662607, 30.4509096],
[-9.6624453, 30.4507585],
[-9.6622731, 30.4506066],
[-9.6621524, 30.4504795],
[-9.6621029, 30.4503685],
[-9.6621122, 30.4502887],
[-9.6621498, 30.4501904],
[-9.6622489, 30.4501037],
[-9.6622705, 30.4500378],
[-9.6622556, 30.4499501],
[-9.6619633, 30.4497187],
[-9.6616613, 30.4495117],
[-9.6613075, 30.4493211],
[-9.6612378, 30.4492285],
[-9.6612136, 30.4491061],
[-9.6613368, 30.4471869],
[-9.6613303, 30.446773],
[-9.6613115, 30.4464377],
[-9.6612605, 30.446204],
[-9.6611747, 30.4460607],
[-9.661046, 30.4459104],
[-9.6604371, 30.4454201],
[-9.6602419, 30.4452349],
[-9.6597022, 30.444812],
[-9.6594018, 30.4445716],
[-9.6587753, 30.4441103],
[-9.6578084, 30.4437339],
[-9.6572506, 30.4436003],
[-9.6569824, 30.4434407],
[-9.6567893, 30.4432557],
[-9.6566498, 30.4430801],
[-9.6563923, 30.4427031],
[-9.6560705, 30.442021],
[-9.6558559, 30.4416626],
[-9.6557008, 30.4413442],
[-9.655475, 30.440807],
[-9.6553946, 30.440652],
[-9.6552444, 30.4404878],
[-9.6550512, 30.4404439],
[-9.6546866, 30.440467],
[-9.6544397, 30.4405387],
[-9.6541957, 30.4406092],
[-9.6537478, 30.4406266],
[-9.6531255, 30.4404878],
[-9.6528519, 30.4402427],
[-9.6527875, 30.4402056],
[-9.652691, 30.4401767],
[-9.652514, 30.4401634],
[-9.6524476, 30.4401588],
[-9.6523879, 30.4401305],
[-9.6523088, 30.4400658],
[-9.6522001, 30.440001],
[-9.6520862, 30.4399698],
[-9.6513928, 30.4398611],
[-9.6510683, 30.4397686],
[-9.6508241, 30.439623],
[-9.6507275, 30.4393732],
[-9.6505344, 30.4388645],
[-9.6500516, 30.4379672],
[-9.6496224, 30.4371902],
[-9.6494374, 30.4368433],
[-9.6492873, 30.4365288],
[-9.649086, 30.4359784],
[-9.648142, 30.4337814],
[-9.6477288, 30.4325973],
[-9.6474633, 30.4319012],
[-9.6474686, 30.4317208],
[-9.6486315, 30.4305973],
[-9.6500424, 30.4290823],
[-9.6502663, 30.4288469],
[-9.6506479, 30.4284331],
[-9.6509808, 30.4277762],
[-9.6510494, 30.4274857],
[-9.65105, 30.4274653],
[-9.6510667, 30.4269521],
[-9.6509912, 30.4265625],
[-9.6508933, 30.4262953],
[-9.6504155, 30.4255978],
[-9.6491268, 30.4240503],
[-9.6489872, 30.4238718],
[-9.6476683, 30.4221862],
[-9.6472554, 30.4216729],
[-9.6466792, 30.4210187],
[-9.6462567, 30.4206129],
[-9.64624, 30.4205991],
[-9.6456901, 30.4201409],
[-9.6417595, 30.4174907],
[-9.6406665, 30.4160379],
[-9.640616, 30.4159571],
[-9.6405394, 30.415895],
[-9.6404467, 30.415859],
[-9.6403457, 30.4158501],
[-9.6402467, 30.4158692],
[-9.6401595, 30.4159141],
[-9.6400932, 30.4159805],
[-9.6400546, 30.4160615],
[-9.6400474, 30.4161488],
[-9.6400726, 30.4162337],
[-9.6401275, 30.4163073],
[-9.6402064, 30.4163624],
[-9.6403015, 30.4163931],
[-9.6403654, 30.4163945],
[-9.6408691, 30.4171315],
[-9.6411699, 30.4176046],
[-9.6411487, 30.417629],
[-9.641131, 30.4176656],
[-9.6411239, 30.4177053],
[-9.6411274, 30.4177541],
[-9.6411416, 30.4177999],
[-9.6411699, 30.4178396],
[-9.641223, 30.4178792],
[-9.641269, 30.4179128],
[-9.6413504, 30.4179342],
[-9.6414424, 30.4179464],
[-9.6415769, 30.4179708],
[-9.6417326, 30.4180441],
[-9.6418884, 30.4181326],
[-9.6421113, 30.4182852],
[-9.642292, 30.4184],
[-9.6424051, 30.4184774],
[-9.6454551, 30.4205384],
[-9.646225, 30.421253],
[-9.6481378, 30.4234607],
[-9.6484548, 30.4238468],
[-9.6485501, 30.4239627],
[-9.6485768, 30.4240075],
[-9.648586, 30.4240571],
[-9.6485768, 30.4241068],
[-9.6485501, 30.4241516],
[-9.6483685, 30.4242776],
[-9.6481479, 30.4244361],
[-9.6478878, 30.4246245],
[-9.647684, 30.4247459],
[-9.6475192, 30.4248338],
[-9.6474266, 30.4248753],
[-9.647276, 30.4246923],
[-9.6471285, 30.4247825],
[-9.647279, 30.4249655],
[-9.6490201, 30.4270355],
[-9.6490891, 30.4271092],
[-9.6490423, 30.4271395],
[-9.6492337, 30.4273493],
[-9.6490926, 30.4274395],
[-9.6489038, 30.4272228],
[-9.6488633, 30.4272491],
[-9.6488409, 30.4272211],
[-9.6450846, 30.4296147],
[-9.6451019, 30.4296368],
[-9.6449567, 30.4297277],
[-9.6449345, 30.4297158],
[-9.6449132, 30.4297174],
[-9.6448946, 30.4297367],
[-9.6408958, 30.4249948],
[-9.6407899, 30.4248695],
[-9.6407596, 30.4248874],
[-9.6407405, 30.4248636],
[-9.6406888, 30.4248943],
[-9.6408094, 30.4250256],
[-9.6408127, 30.4250424],
[-9.6408031, 30.4250554],
[-9.638494, 30.4265073],
[-9.6384504, 30.4265125],
[-9.6384128, 30.4265009],
[-9.637755, 30.4257793],
[-9.6377409, 30.4257348],
[-9.637747, 30.4257019],
[-9.6384554, 30.425241],
[-9.6384618, 30.4252264],
[-9.6384581, 30.4252144],
[-9.6383734, 30.4251395],
[-9.6383513, 30.4251546],
[-9.6382725, 30.4250615],
[-9.6383015, 30.4250435],
[-9.638204, 30.4249253],
[-9.6381945, 30.4249243],
[-9.6381827, 30.4249279],
[-9.6374898, 30.4253694],
[-9.6374524, 30.4253682],
[-9.6374239, 30.4253602],
[-9.637394, 30.4253479],
[-9.6373702, 30.4253285],
[-9.6366908, 30.4244902],
[-9.6366845, 30.4244714],
[-9.6366912, 30.4244541],
[-9.6383938, 30.4231465],
[-9.6384213, 30.4231237],
[-9.638533, 30.4230501],
[-9.6385798, 30.4230864],
[-9.6385906, 30.4230967],
[-9.638593, 30.4231112],
[-9.638593, 30.4231236],
[-9.6385846, 30.4231381],
[-9.638457, 30.4232585],
[-9.6385252, 30.4233247],
[-9.6390224, 30.4229438],
[-9.6389541, 30.4228775],
[-9.638897, 30.4229127],
[-9.6388631, 30.4229284],
[-9.6388281, 30.4229473],
[-9.6388148, 30.4229543],
[-9.6387997, 30.4229572],
[-9.6387843, 30.4229559],
[-9.6387701, 30.4229505],
[-9.6387586, 30.4229415],
[-9.638751, 30.4229298],
[-9.6387481, 30.4229166],
[-9.6387502, 30.4229033],
[-9.6387575, 30.4228908],
[-9.6387839, 30.4228514],
[-9.6388731, 30.4227802],
[-9.6395771, 30.4222426],
[-9.6397542, 30.422223],
[-9.6399127, 30.4221719],
[-9.6400144, 30.4220818],
[-9.6400514, 30.4219723],
[-9.6400342, 30.4218939],
[-9.639975, 30.4218184],
[-9.6398309, 30.4217794],
[-9.6397175, 30.421794],
[-9.6396074, 30.4218671],
[-9.639438, 30.4220058],
[-9.6385966, 30.4226712],
[-9.6384229, 30.4228085],
[-9.6383545, 30.4228281],
[-9.638274, 30.42282],
[-9.6381327, 30.422668],
[-9.6381249, 30.4226606],
[-9.6381147, 30.4226558],
[-9.6381033, 30.4226541],
[-9.6380919, 30.4226556],
[-9.6380816, 30.4226602],
[-9.6379488, 30.4227453],
[-9.6379375, 30.4227486],
[-9.6379257, 30.4227484],
[-9.6379146, 30.422745],
[-9.6379053, 30.4227385],
[-9.6378472, 30.4226758],
[-9.6378394, 30.4226684],
[-9.6378293, 30.4226636],
[-9.6378178, 30.4226619],
[-9.6378064, 30.4226634],
[-9.6377961, 30.422668],
[-9.637788, 30.4226752],
[-9.6376383, 30.4224993],
[-9.6376214, 30.4224948],
[-9.6376075, 30.4224985],
[-9.6363904, 30.423474],
[-9.6363576, 30.4234902],
[-9.6363019, 30.4234971],
[-9.6338303, 30.4234382],
[-9.6338276, 30.4237754],
[-9.6337272, 30.4237748],
[-9.6337298, 30.4234377],
[-9.6332973, 30.4234221],
[-9.6332852, 30.4234156],
[-9.6332776, 30.4234087],
[-9.6332711, 30.4233975],
[-9.6332677, 30.4233878],
[-9.6332608, 30.4233767],
[-9.63325, 30.4233683],
[-9.6332366, 30.4233634],
[-9.6330819, 30.4233578],
[-9.6330531, 30.4233393],
[-9.6330363, 30.4233017],
[-9.6330209, 30.422914],
[-9.6330263, 30.4229034],
[-9.6330348, 30.422896],
[-9.6330501, 30.4228926],
[-9.6332028, 30.4229028],
[-9.6332165, 30.4229016],
[-9.6332291, 30.4228969],
[-9.6332392, 30.422889],
[-9.633246, 30.4228787],
[-9.6332487, 30.4228663],
[-9.6332544, 30.4228534],
[-9.6332646, 30.4228411],
[-9.6332933, 30.4228307],
[-9.633439, 30.4228351],
[-9.6334558, 30.4228258],
[-9.6334662, 30.4228038],
[-9.6335124, 30.4213906],
[-9.6335208, 30.4213726],
[-9.6335304, 30.4213649],
[-9.6364835, 30.4214288],
[-9.6364964, 30.4214261],
[-9.6365048, 30.4214167],
[-9.6365111, 30.4212987],
[-9.6365241, 30.4212534],
[-9.6365604, 30.4212088],
[-9.6365946, 30.4211707],
[-9.6366164, 30.4211498],
[-9.636654, 30.4211356],
[-9.6372941, 30.4211781],
[-9.6373278, 30.421174],
[-9.6373582, 30.4211609],
[-9.6373822, 30.4211402],
[-9.6373973, 30.421114],
[-9.6374021, 30.421085],
[-9.6374093, 30.4209452],
[-9.6375521, 30.4209519],
[-9.6375577, 30.4209739],
[-9.6375976, 30.4210304],
[-9.6376566, 30.4210728],
[-9.6377283, 30.4210964],
[-9.6378051, 30.4210985],
[-9.6378786, 30.4210791],
[-9.6379408, 30.4210401],
[-9.6379849, 30.4209859],
[-9.6380061, 30.4209222],
[-9.6380023, 30.420856],
[-9.6379737, 30.4207945],
[-9.6379235, 30.4207443],
[-9.6378571, 30.4207109],
[-9.6377817, 30.420698],
[-9.6373348, 30.4206721],
[-9.6325153, 30.4202885],
[-9.6323839, 30.4202076],
[-9.6323329, 30.4201243],
[-9.6320222, 30.4189951],
[-9.6313673, 30.4166164],
[-9.631287, 30.4163366],
[-9.6312714, 30.4162703],
[-9.6312312, 30.4162123],
[-9.6311709, 30.4161691],
[-9.6310974, 30.4161455],
[-9.631019, 30.4161443],
[-9.6309446, 30.4161657],
[-9.6308826, 30.4162071],
[-9.6308401, 30.4162639],
[-9.6308218, 30.4163296],
[-9.630829, 30.4163941],
[-9.6316349, 30.4190713],
[-9.6316292, 30.4190942],
[-9.6316107, 30.419114],
[-9.6315014, 30.4191563],
[-9.6314944, 30.419178],
[-9.6314988, 30.4192084],
[-9.6323986, 30.4222247],
[-9.6323986, 30.423729],
[-9.6323892, 30.4237562],
[-9.6323637, 30.4237811],
[-9.6318186, 30.4237562],
[-9.6317834, 30.4237552],
[-9.6317703, 30.4237594],
[-9.6317619, 30.4237665],
[-9.6317495, 30.4239451],
[-9.6317402, 30.4239594],
[-9.6317241, 30.4239654],
[-9.6316467, 30.4239539],
[-9.6316317, 30.4239534],
[-9.6314152, 30.4239514],
[-9.6313908, 30.4239507],
[-9.6311833, 30.4239536],
[-9.6311659, 30.4239533],
[-9.6292437, 30.4239284],
[-9.6292231, 30.4239234],
[-9.6292142, 30.4239111],
[-9.6290559, 30.4227269],
[-9.62907, 30.4226771],
[-9.6290955, 30.4226523],
[-9.6305007, 30.4222121],
[-9.6307874, 30.4221215],
[-9.6309684, 30.4220668],
[-9.6311033, 30.422026],
[-9.6310756, 30.4219698],
[-9.6310153, 30.4219895],
[-9.631021, 30.4220049],
[-9.6309534, 30.4220267],
[-9.6309355, 30.4219916],
[-9.6308924, 30.4219221],
[-9.6308321, 30.4218526],
[-9.6307495, 30.4217826],
[-9.6307127, 30.4217558],
[-9.6306679, 30.4217404],
[-9.6306197, 30.4217379],
[-9.6303982, 30.4217627],
[-9.6303721, 30.4217669],
[-9.6303526, 30.4217669],
[-9.6303322, 30.421762],
[-9.6303159, 30.4217536],
[-9.6303026, 30.4217327],
[-9.6302987, 30.4217157],
[-9.6302888, 30.4217005],
[-9.6302737, 30.421689],
[-9.6302552, 30.4216821],
[-9.6302351, 30.4216807],
[-9.6302155, 30.421685],
[-9.630199, 30.4216835],
[-9.6301862, 30.4216769],
[-9.6301678, 30.4216063],
[-9.6301471, 30.4215948],
[-9.6301201, 30.4215977],
[-9.6279785, 30.4222848],
[-9.6212085, 30.4244043],
[-9.6211873, 30.4244199],
[-9.621183, 30.4244419],
[-9.6212279, 30.4245558],
[-9.6212282, 30.4245818],
[-9.6212152, 30.4246066],
[-9.6211672, 30.4246174],
[-9.6211534, 30.4246217],
[-9.6209804, 30.4246614],
[-9.6209689, 30.4246652],
[-9.6209241, 30.4246751],
[-9.6208956, 30.4246818],
[-9.6208728, 30.4246812],
[-9.6208484, 30.4246751],
[-9.620834, 30.4246627],
[-9.6208213, 30.4246449],
[-9.6205974, 30.4241323],
[-9.6205639, 30.424047],
[-9.6205162, 30.4239324],
[-9.6206765, 30.4238785],
[-9.6206353, 30.4237875],
[-9.6204788, 30.4238403],
[-9.6204068, 30.4236662],
[-9.6203995, 30.4236488],
[-9.6201852, 30.4232189],
[-9.6201778, 30.4231657],
[-9.6201919, 30.4231346],
[-9.6210472, 30.4228729],
[-9.6214802, 30.4227456],
[-9.6214992, 30.4227399],
[-9.6215954, 30.4227156],
[-9.6216132, 30.4227133],
[-9.6216289, 30.422728],
[-9.621651, 30.4227867],
[-9.6216591, 30.4227925],
[-9.6216763, 30.4227959],
[-9.6217709, 30.4227584],
[-9.6217778, 30.4227482],
[-9.6217742, 30.4227291],
[-9.62146, 30.4219814],
[-9.6214555, 30.4219711],
[-9.6213791, 30.4217703],
[-9.6214051, 30.4217631],
[-9.6213994, 30.4217472],
[-9.6213873, 30.421751],
[-9.6213812, 30.4217363],
[-9.6213942, 30.4217325],
[-9.6213889, 30.4217174],
[-9.6213612, 30.4217252],
[-9.6212879, 30.4215393],
[-9.6212893, 30.4215309],
[-9.6212956, 30.421525],
[-9.6213712, 30.4214988],
[-9.6214281, 30.421482],
[-9.6214626, 30.4214723],
[-9.6214956, 30.4215593],
[-9.6214728, 30.4215659],
[-9.6214905, 30.4216115],
[-9.6215133, 30.4216049],
[-9.6215387, 30.4216707],
[-9.6215163, 30.421677],
[-9.6215214, 30.4216908],
[-9.6215351, 30.4216866],
[-9.6215413, 30.4217017],
[-9.6215276, 30.4217059],
[-9.6215335, 30.4217221],
[-9.6215561, 30.421716],
[-9.6215668, 30.421713],
[-9.6215496, 30.4216676],
[-9.6214735, 30.4214692],
[-9.6276847, 30.4195437],
[-9.6277219, 30.4194998],
[-9.6277155, 30.4194524],
[-9.627452, 30.4188579],
[-9.627458, 30.418829],
[-9.6274848, 30.418799],
[-9.6288174, 30.4183877],
[-9.6290808, 30.4182981],
[-9.6291214, 30.4182712],
[-9.6291495, 30.4182342],
[-9.6291619, 30.4181913],
[-9.6291588, 30.4181619],
[-9.6292685, 30.4181277],
[-9.6292832, 30.418122],
[-9.6292958, 30.4181118],
[-9.6293036, 30.4180985],
[-9.6293058, 30.4180838],
[-9.6293022, 30.4180693],
[-9.6292932, 30.4180567],
[-9.6292797, 30.4180474],
[-9.6292634, 30.4180426],
[-9.6292462, 30.4180427],
[-9.6292301, 30.4180479],
[-9.6291104, 30.4180841],
[-9.6290898, 30.4180792],
[-9.6290541, 30.4180684],
[-9.6290046, 30.4180572],
[-9.6289562, 30.4180624],
[-9.6288551, 30.4180899],
[-9.6204632, 30.4207423],
[-9.6202332, 30.4208064],
[-9.6200563, 30.4208491],
[-9.6199571, 30.4208461],
[-9.619858, 30.420837],
[-9.6197766, 30.4208034],
[-9.6196917, 30.4207515],
[-9.619156, 30.4203646],
[-9.6175616, 30.4190466],
[-9.6174988, 30.4190066],
[-9.617429, 30.4189738],
[-9.6173504, 30.4189633],
[-9.6172723, 30.4189763],
[-9.6172093, 30.4190076],
[-9.6171606, 30.4190541],
[-9.6171309, 30.4191113],
[-9.6171234, 30.4191737],
[-9.6171385, 30.419235],
[-9.617175, 30.4192892],
[-9.6172292, 30.419331],
[-9.6172957, 30.4193563],
[-9.617368, 30.4193626],
[-9.6174454, 30.419347],
[-9.617636, 30.4194953],
[-9.618023, 30.4198139],
[-9.6189119, 30.4204953],
[-9.6189679, 30.4205483],
[-9.6189443, 30.4206333],
[-9.6189403, 30.4206525],
[-9.6187336, 30.4215765],
[-9.6185577, 30.4225849],
[-9.6183706, 30.4233905],
[-9.6182666, 30.423376],
[-9.6182608, 30.4233752],
[-9.618255, 30.4233744],
[-9.6168825, 30.4231646],
[-9.6168411, 30.4231583],
[-9.6168259, 30.4231555],
[-9.6166771, 30.4231347],
[-9.6170449, 30.4213446],
[-9.6170452, 30.4213326],
[-9.6171349, 30.4209756],
[-9.617149, 30.4208969],
[-9.6171727, 30.4209037],
[-9.6172415, 30.4207355],
[-9.6172602, 30.4206811],
[-9.6172509, 30.4206343],
[-9.617225, 30.4205973],
[-9.6171776, 30.4205682],
[-9.6171215, 30.4205548],
[-9.6170635, 30.4205584],
[-9.6170104, 30.4205788],
[-9.6169683, 30.4206135],
[-9.6167848, 30.4207663],
[-9.6166344, 30.4209144],
[-9.6166042, 30.4209608],
[-9.6164637, 30.4213369],
[-9.6164663, 30.4213855],
[-9.6164924, 30.4213879],
[-9.6164912, 30.4216364],
[-9.6164517, 30.4218564],
[-9.6164062, 30.4220456],
[-9.6163983, 30.4221189],
[-9.6163726, 30.4222093],
[-9.6163331, 30.4222843],
[-9.6162896, 30.4223355],
[-9.6162243, 30.4223866],
[-9.6161492, 30.4224378],
[-9.6160108, 30.4224957],
[-9.6159633, 30.4225145],
[-9.6159524, 30.4224948],
[-9.6158737, 30.4225274],
[-9.6158898, 30.4225564],
[-9.6158447, 30.4225912],
[-9.615817, 30.4226202],
[-9.6157953, 30.4226509],
[-9.6157834, 30.4226747],
[-9.6157705, 30.4226933],
[-9.615726, 30.4227113],
[-9.6153022, 30.4226963],
[-9.6150635, 30.4226836],
[-9.6148369, 30.42265],
[-9.6145472, 30.4226072],
[-9.6143071, 30.422539],
[-9.6139566, 30.4224424],
[-9.6130708, 30.4221616],
[-9.6121399, 30.4217815],
[-9.6111354, 30.4212137],
[-9.6103401, 30.4205857],
[-9.609427, 30.419708],
[-9.6088555, 30.4191205],
[-9.6075141, 30.4173993],
[-9.6057527, 30.4149021],
[-9.6049207, 30.4132661],
[-9.6044701, 30.4119037],
[-9.6041214, 30.4108789],
[-9.6034613, 30.4095384],
[-9.6028935, 30.4085216],
[-9.6019837, 30.4058199],
[-9.6017262, 30.4049061],
[-9.6009994, 30.402019],
[-9.6007392, 30.4007039],
[-9.6006989, 30.4003939],
[-9.6006238, 30.3999392],
[-9.6004305, 30.398748],
[-9.6002805, 30.3976218],
[-9.6002586, 30.3972451],
[-9.6002457, 30.3969341],
[-9.6002591, 30.3966368],
[-9.6002564, 30.3963407],
[-9.6002094, 30.39562],
[-9.6001679, 30.3953227],
[-9.6001518, 30.3950231],
[-9.6001947, 30.3942758],
[-9.6002618, 30.3936835],
[-9.6003476, 30.3930126],
[-9.6003798, 30.392182],
[-9.6004564, 30.3914807],
[-9.6004864, 30.3911601],
[-9.6005152, 30.3907383],
[-9.600538, 30.3905358],
[-9.6005836, 30.3902871],
[-9.6007501, 30.3895833],
[-9.6008277, 30.3891187],
[-9.6008867, 30.3887879],
[-9.6009511, 30.3884616],
[-9.6010423, 30.3878716],
[-9.6011388, 30.3874042],
[-9.6012649, 30.3869646],
[-9.6014753, 30.3863829],
[-9.6016731, 30.385716],
[-9.601757, 30.3853954],
[-9.6018709, 30.3849301],
[-9.6019308, 30.3845992],
[-9.6020266, 30.3842274],
[-9.6023847, 30.3833829],
[-9.6024732, 30.3831272],
[-9.6025255, 30.3829629],
[-9.6026261, 30.3826274],
[-9.6027575, 30.3822433],
[-9.6028479, 30.3819844],
[-9.6029152, 30.381837],
[-9.6030003, 30.3816284],
[-9.6031089, 30.3813779],
[-9.6032859, 30.3810123],
[-9.6034281, 30.3807161],
[-9.6035851, 30.3804059],
[-9.6038548, 30.380075],
[-9.6041942, 30.3797498],
[-9.6042744, 30.3796044],
[-9.6042545, 30.3794561],
[-9.6041353, 30.3789226],
[-9.6040889, 30.3785104],
[-9.6040772, 30.3782274],
[-9.6042354, 30.3779972],
[-9.6043253, 30.3778456],
[-9.6045345, 30.3776189],
[-9.6048148, 30.3773089],
[-9.605012, 30.3771885],
[-9.605252, 30.3770832],
[-9.6054478, 30.3769999],
[-9.6056503, 30.376875],
[-9.6054988, 30.3767188],
[-9.6052621, 30.3764909],
[-9.6050374, 30.3762514],
[-9.6048953, 30.3759447],
[-9.6048617, 30.3757353],
[-9.6048215, 30.3753709],
[-9.6047531, 30.3750608],
[-9.6048591, 30.3744093],
[-9.60481, 30.3733052],
[-9.6049153, 30.3726714],
[-9.6049646, 30.3723014],
[-9.6051541, 30.3720397],
[-9.6054536, 30.3719047],
[-9.6058527, 30.3715851],
[-9.6062122, 30.3712853],
[-9.6071389, 30.3719519],
[-9.6071818, 30.3720583],
[-9.6072704, 30.3721393],
[-9.6074072, 30.3721185],
[-9.6074769, 30.3720236],
[-9.6074286, 30.3719033],
[-9.6073025, 30.3718176],
[-9.6072957, 30.3718124],
[-9.606514, 30.3712183],
[-9.6063121, 30.3711092],
[-9.6056781, 30.3709301],
[-9.6050817, 30.3708735],
[-9.6045948, 30.3708039],
[-9.6044513, 30.3707623],
[-9.604399, 30.3706905],
[-9.6043615, 30.370583],
[-9.6042797, 30.3704487],
[-9.6042509, 30.3703874],
[-9.6042838, 30.3702121],
[-9.6043099, 30.3700686],
[-9.6044057, 30.3697628],
[-9.6046794, 30.3687792],
[-9.604556, 30.3679831],
[-9.6042824, 30.3674693],
[-9.6044808, 30.3662012],
[-9.6030562, 30.3650105],
[-9.6000508, 30.3637044],
[-9.598725, 30.3632022],
[-9.5892621, 30.3619706],
[-9.5794023, 30.3622501],
[-9.5728571, 30.361897],
[-9.5702892, 30.3618526],
[-9.5695659, 30.3622076],
[-9.5689918, 30.3626688],
[-9.5684393, 30.3631695],
[-9.567388, 30.3641307],
[-9.567253, 30.3643833],
[-9.5671385, 30.3646676],
[-9.5665377, 30.3662621],
[-9.5663848, 30.3665954],
[-9.5662185, 30.3668175],
[-9.5654085, 30.3674702],
[-9.5649069, 30.367808],
[-9.5644097, 30.3681638],
[-9.5638813, 30.3683073],
[-9.5612085, 30.3689235],
[-9.5607876, 30.3690272],
[-9.5604447, 30.3691549],
[-9.5597995, 30.3694747],
[-9.5593134, 30.3698093],
[-9.5588933, 30.3701907],
[-9.5585945, 30.3705736],
[-9.558336, 30.3709404],
[-9.5582639, 30.3710653],
[-9.5581402, 30.3713689],
[-9.5580367, 30.3716916],
[-9.5579788, 30.3718314],
[-9.5579684, 30.371974],
[-9.5579434, 30.372246],
[-9.5579321, 30.3725543],
[-9.5579052, 30.3734123],
[-9.5579037, 30.3735262],
[-9.5578982, 30.3738767],
[-9.5578037, 30.3741655],
[-9.5576599, 30.3744492],
[-9.5573046, 30.3749067],
[-9.5572791, 30.3749291],
[-9.5571371, 30.375103],
[-9.5569813, 30.3752262],
[-9.5568962, 30.3753443],
[-9.5570591, 30.3754744],
[-9.557976, 30.3762242],
[-9.5589603, 30.3769623],
[-9.5617535, 30.3789017],
[-9.5629665, 30.3799958],
[-9.5628646, 30.3801027],
[-9.5622845, 30.3801586],
[-9.5600473, 30.3799486],
[-9.5565687, 30.3795989],
[-9.553549, 30.3792892],
[-9.5442102, 30.3783515],
[-9.5428437, 30.3873648],
[-9.5443524, 30.3908542],
[-9.5433105, 30.3925618],
[-9.5427526, 30.3931541],
[-9.5421784, 30.3957837],
[-9.5352602, 30.3946103],
[-9.5346749, 30.3944837],
[-9.5341802, 30.3943201],
[-9.5335272, 30.3938399],
[-9.5330266, 30.3943531],
[-9.5314971, 30.3959283],
[-9.530462, 30.3969167],
[-9.529196, 30.3981336],
[-9.5271941, 30.4001245],
[-9.5245492, 30.398102],
[-9.5238951, 30.3975989],
[-9.5230152, 30.3969587],
[-9.5132387, 30.3894092],
[-9.5129783, 30.3892892],
[-9.5120899, 30.3885789],
[-9.510206, 30.3871162],
[-9.5085269, 30.3858621],
[-9.5071161, 30.3847839],
[-9.5064429, 30.384254],
[-9.5082292, 30.3838283],
[-9.5103589, 30.3833007],
[-9.5133375, 30.3821299],
[-9.5161055, 30.381047],
[-9.5180487, 30.3803127],
[-9.519332, 30.3801923],
[-9.5192314, 30.3777037],
[-9.5189668, 30.373181],
[-9.5186342, 30.3701727],
[-9.51872, 30.3691082],
[-9.5207156, 30.364165],
[-9.5246477, 30.3597071],
[-9.5225869, 30.3589255],
[-9.5205404, 30.3581235],
[-9.5186556, 30.3573602],
[-9.5177428, 30.3570276],
[-9.5160813, 30.356412],
[-9.5073538, 30.3529429],
[-9.5068153, 30.3527242],
[-9.5059215, 30.352179],
[-9.5046884, 30.3514962],
[-9.5042646, 30.350987],
[-9.5040071, 30.3507232],
[-9.5018291, 30.3518758],
[-9.4987019, 30.3528562],
[-9.4968329, 30.3542381],
[-9.4948175, 30.3563845],
[-9.4940781, 30.3575811],
[-9.4923547, 30.3589295],
[-9.4914639, 30.3598667],
[-9.4902515, 30.3603665],
[-9.4899937, 30.3604941],
[-9.4897043, 30.3605887],
[-9.4888216, 30.3609177],
[-9.4879555, 30.3612367],
[-9.4866517, 30.361605],
[-9.4852204, 30.3620244],
[-9.484392, 30.3651227],
[-9.4842479, 30.3654886],
[-9.484185, 30.3658389],
[-9.484066, 30.3662676],
[-9.484115, 30.3667386],
[-9.4842277, 30.3669285],
[-9.4843002, 30.3670505],
[-9.4849968, 30.3678075],
[-9.4854046, 30.3682122],
[-9.4857295, 30.3685345],
[-9.4863009, 30.3687298],
[-9.4866135, 30.3688703],
[-9.4868375, 30.3692386],
[-9.4869495, 30.3698425],
[-9.4871875, 30.3703014],
[-9.4874001, 30.3707023],
[-9.4872504, 30.3712132],
[-9.4870195, 30.3718774],
[-9.4869705, 30.3723061],
[-9.4870194, 30.3729522],
[-9.4871245, 30.3735319],
[-9.4875024, 30.3739546],
[-9.4879013, 30.3742987],
[-9.4890259, 30.3769546],
[-9.4903778, 30.3791862],
[-9.49203, 30.3857631],
[-9.4907211, 30.3891994],
[-9.4936051, 30.3956076],
[-9.4928755, 30.4010489],
[-9.4927555, 30.4049946],
[-9.4967766, 30.4108629],
[-9.5018686, 30.4171915],
[-9.5080999, 30.4240749],
[-9.5173803, 30.4273628],
[-9.5250192, 30.4331354],
[-9.5325294, 30.4370576],
[-9.541885, 30.4437915],
[-9.5481076, 30.4493041],
[-9.5548025, 30.4525228],
[-9.55896, 30.4529074],
[-9.5636, 30.4533366],
[-9.5726552, 30.4520418],
[-9.5823326, 30.4488972],
[-9.5875897, 30.4485272],
[-9.592824, 30.4489882],
[-9.5969882, 30.450488],
[-9.6037109, 30.4522138],
[-9.6087457, 30.4542231],
[-9.6129269, 30.4564997],
[-9.6203942, 30.4611848],
[-9.6220079, 30.4637871],
[-9.6225035, 30.4644401],
[-9.6236914, 30.465997],
[-9.6319642, 30.4732378],
[-9.6375433, 30.4751242],
[-9.6465555, 30.4768995],
[-9.6570268, 30.4791926],
[-9.6635929, 30.4857017],
[-9.6662106, 30.4911011],
[-9.6673265, 30.4926912],
[-9.6711438, 30.4932089],
[-9.6750126, 30.493159],
[-9.6765238, 30.4933553],

    ]]]
  }
};